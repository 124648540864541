<template>
  <div class="d-flex flex-wrap px-4">
    <div
      class="col-12 p-0 form--group"
      :class="{ 'has-error': validation.hasError('propertyPrice') }"
      v-if="!listing"
    >
      <div class="col-form-label">{{ $t('mortgage.propertyPrice') }}</div>
      <div>
        <cleave
          class="basic--input"
          name="propertyPrice"
          v-model="propertyPrice"
          :options="cleaveOptionsPrice"
        />
        <span class="val-error" v-if="validation.hasError('propertyPrice')">{{
          validation.firstError('propertyPrice')
        }}</span>
      </div>
    </div>
    <div class="col-6 pl-0" v-if="!listing">
      <div
        class="form--group"
        :class="{ 'has-error': validation.firstError('selectedPropertyType') }"
      >
        <div class="col-form-label">{{ $t('mortgage.propertyType') }}</div>
        <div>
          <multiselect
            v-model="selectedPropertyType"
            select-label=""
            deselect-label=""
            name="selectedPropertyType"
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :options="propertyTypes"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('propertyPrice')">{{
            validation.firstError('selectedPropertyType')
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="!listing"></div>
    <div
      class="col-12 p-0 form--group"
      :class="{ 'has-error': validation.hasError('propertyAddress') }"
      v-if="!listing"
    >
      <div class="col-form-label">{{ $t('mortgage.propertyAddress') }}</div>
      <div>
        <textarea
          v-model="propertyAddress"
          type="text"
          class="basic--input"
          name="propertyAddress"
          rows="3"
        />
        <span class="val-error" v-if="validation.hasError('propertyAddress')">{{
          validation.firstError('propertyAddress')
        }}</span>
      </div>
    </div>
    <div class="col-6 pl-0">
      <div class="form--group" :class="{ 'has-error': validation.hasError('loanAmount') }">
        <div class="col-form-label">{{ $t('mortgage.loanAmount') }}</div>
        <div>
          <cleave
            class="basic--input"
            name="loanAmount"
            v-model="loanAmount"
            :options="cleaveOptionsPrice"
          />
          <span class="val-error" v-if="validation.hasError('loanAmount')">{{
            validation.firstError('loanAmount')
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-6 pr-0 mb-4">
      <div class="form--group" :class="{ 'has-error': validation.hasError('tenureInYear') }">
        <div class="col-form-label">{{ $t('mortgage.loanTenure') }}</div>
        <div>
          <input v-model="tenureInYear" type="text" class="basic--input" name="tenureInYear" />
          <span class="val-error" v-if="validation.hasError('tenureInYear')">{{
            validation.firstError('tenureInYear')
          }}</span>
        </div>
      </div>
    </div>
    <div
      class="custom-control custom-checkbox terms--conditions"
      :class="{ 'has-error': validation.hasError('termsAndConditions') }"
    >
      <input
        type="checkbox"
        class="custom-control-input"
        id="termsAndConditions"
        name="termsAndConditions"
        v-model="termsAndConditions"
      />
      <label class="custom-control-label" for="termsAndConditions">{{
        $t('mortgage.agreement')
      }}</label>
    </div>
    <span class="val-error" v-if="validation.hasError('termsAndConditions')">{{
      validation.firstError('termsAndConditions')
    }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
const Cleave = () => import('vue-cleave-component');
import Multiselect from 'vue-multiselect';

require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  props: ['listing'],
  components: {
    Cleave,
    Multiselect,
  },
  computed: {
    ...mapState({
      propertyTypes: state => state.v2.masters.propertyTypes,
      ltv: state => state.v2.mortgage.ltv,
      propertyPriceValue: state => state.v2.mortgage.propertyPrice,
      loanAmountValue: state => state.v2.mortgage.loanAmount,
    }),
    propertyPrice: {
      get() {
        return this.propertyPriceValue;
      },
      set(val) {
        const propertyPriceValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('S$', '').trim())
            : val
          : 0;
        this.$store.commit('v2/mortgage/SET_PROPERTY_PRICE', propertyPriceValue);
      },
    },
    loanAmount: {
      get() {
        return this.loanAmountValue;
      },
      set(val) {
        const loanAmountValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('S$', '').trim())
            : val
          : 0;
        this.$store.commit('v2/mortgage/SET_LOAN_AMOUNT', loanAmountValue);
      },
    },
  },
  data() {
    return {
      cleaveOptionsPrice: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        prefix: 'S$ ',
      },
      selectedPropertyType: null,
      propertyAddress: null,
      tenureInYear: '5',
      termsAndConditions: false,
    };
  },
  validators: {
    // eslint-disable-next-line no-unused-vars
    propertyPrice(value) {
      if (this.listing) {
        return Validator.value(value);
      } else {
        return Validator.value(this.propertyPriceValue)
          .required(this.$i18n.t('errors.propertyPrice.required'))
          .float()
          .greaterThan(0);
      }
    },
    selectedPropertyType(value) {
      if (this.listing) {
        return Validator.value(value);
      } else {
        return Validator.value(value).required(this.$i18n.t('errors.propertyType.required'));
      }
    },
    propertyAddress(value) {
      if (this.listing) {
        return Validator.value(value);
      } else {
        return Validator.value(value).required(this.$i18n.t('errors.propertyAddress.required'));
      }
    },
    // eslint-disable-next-line no-unused-vars
    loanAmount(value) {
      let validator = Validator.value(this.loanAmountValue)
        .required(this.$i18n.t('errors.loanAmount.required'))
        .float();
      if (this.listing && !this.listing.view_to_offer) {
        validator = validator
          .greaterThan(0)
          .lessThanOrEqualTo(this.listing.sell_price_raw * this.ltv);
      }
      return validator;
    },
    tenureInYear(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.tenureInYear.required'))
        .float()
        .greaterThanOrEqualTo(1)
        .lessThanOrEqualTo(30);
    },
    termsAndConditions(value) {
      return Validator.value(value).in([true], this.$i18n.t('errors.termsAndConditions.required'));
    },
  },
  methods: {
    validate: function() {
      this.$store.commit('v2/mortgage/SET_SELECTED_PROPERTY_TYPE', this.selectedPropertyType);
      this.$store.commit('v2/mortgage/SET_PROPERTY_ADDRESS', this.propertyAddress);
      this.$store.commit('v2/mortgage/SET_TENURE_IN_YEAR', this.tenureInYear);
      this.$store.commit('v2/mortgage/SET_TERMS_CONDITIONS', this.termsAndConditions);
      return this.$validate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.form--group {
  display: block;
  .val-error {
    margin-top: 8px;
  }
}
.terms--conditions {
  font-size: $font-sm;
}
</style>
